import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import PrivacyPolicy from './Policy/PrivacyPolicy';
import DataDeletion from './Policy/DataDeletion';
import Footer from './components/Footer';
import Dashboard from './Doctor/Dashboard';
import Reports from './Doctor/Reports'; // Import Reports component
import Account from './Doctor/Account'; // Import Account component

const AppRouter = () => {
  const location = useLocation();

  // Define paths where Header and Footer should not appear
  const noHeaderFooterPaths = ['/dashboard', '/patients', '/reports'];

  // Conditionally render Header and Footer based on the path
  const shouldRenderHeaderFooter = !noHeaderFooterPaths.some((path) => location.pathname.startsWith(path));

  return (
    <>
      {/* Conditionally render Header */}
      {shouldRenderHeaderFooter && <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/data-delete-policy" element={<DataDeletion />} />
        
        {/* Render Dashboard, Reports, and Account for Doctor routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reports" element={<Reports />} /> {/* Reports route */}
        <Route path="/account" element={<Account />} /> {/* Account route */}
      </Routes>

      {/* Conditionally render Footer */}
      {shouldRenderHeaderFooter && <Footer />}
    </>
  );
};

export default AppRouter;
