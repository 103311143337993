import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { fetchSignInMethodsForEmail, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import './Signup.css';

const Signup = () => {
  const navigate = useNavigate();
  const [hospitalClinicName, setHospitalClinicName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSignUp = async () => {
    if (!/^\d{10}$/.test(mobileNumber)) {
      setError('Mobile number must be exactly 10 digits');
      return;
    }

    try {
      const existingUser = await fetchSignInMethodsForEmail(auth, email);
      if (existingUser.length > 0) {
        setError('Email already in use. Please sign in instead.');
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      const userData = {
        userRole: 'NA',
        email,
        hospitalClinicName,
        mobileNumber,
        address,
      };

      await setDoc(doc(firestore, 'Corporate', userId), userData);

      // Send email verification
      await sendEmailVerification(userCredential.user);
      setSuccess('Registration successful! Please check your email to verify your account.');
      setTimeout(() => navigate('/login'), 3000); // Navigate to login after 3 seconds
    } catch (error) {
      console.error('Error creating user:', error);
      switch (error.code) {
        case 'auth/invalid-email':
          setError('The email address is not valid.');
          break;
        case 'auth/weak-password':
          setError('The password is too weak. Please choose a stronger password.');
          break;
        default:
          setError('Registration failed. Please check your credentials.');
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    handleSignUp();
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h1 className="signup-header">Sign Up Here</h1>
        <div className="form-group">
          <input
            type="text"
            placeholder="Hospital/Clinic Name"
            value={hospitalClinicName}
            onChange={(e) => {
              setHospitalClinicName(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter Your Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            placeholder="Enter Mobile Number"
            value={mobileNumber}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError('');
            }}
            required
          />
        </div>

        {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
        {success && <p className="success-message" style={{ color: 'green' }}>{success}</p>}
        
        <button type="submit" className="signup-button">Sign Up</button>
        <div className="signup-prompt">
          <p className="signup-text">
            Already have an account? <Link to="/login" className="signup">Log In</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signup;

