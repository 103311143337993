import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import {
  FaHome,
  FaUserAlt,
  FaClipboardList,
  FaNotesMedical,
  FaCog,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import "./Sidebar.css";

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const [openMenu, setOpenMenu] = useState(null);

  // Close submenus when the sidebar is collapsed
  useEffect(() => {
    if (isCollapsed) setOpenMenu(null);
  }, [isCollapsed]);

  const handleSubMenuToggle = (menu) => {
    if (!isCollapsed) {
      setOpenMenu(openMenu === menu ? null : menu);
    }
  };

  // Sidebar navigation items with optional submenus
  const navItems = [
    {
      label: "Home",
      icon: <FaHome />,
      link: "#home",
    },
    {
      label: "Patients",
      icon: <FaUserAlt />,
      submenu: [
        { label: "Patient List", link: "#patientList" },
        { label: "Patient Records", link: "#patientRecords" },
      ],
    },
    {
      label: "Appointments",
      icon: <FaClipboardList />,
      submenu: [
        { label: "Scheduled Appointments", link: "#scheduledAppointments" },
        { label: "Appointment History", link: "#appointmentHistory" },
      ],
    },
    {
      label: "Prescriptions",
      icon: <FaNotesMedical />,
      submenu: [
        { label: "New Prescriptions", link: "#newPrescriptions" },
        { label: "Prescription History", link: "#prescriptionHistory" },
      ],
    },
    {
      label: "Settings",
      icon: <FaCog />,
      link: "#settings",
    },
  ];

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      {/* Sidebar Header */}
      <div className="sidebar-header">
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </button>
        {!isCollapsed && <h2 className="sidebar-title">CureClix</h2>}
      </div>

      {/* Sidebar Navigation */}
      <Nav className="flex-column">
        {navItems.map((item, index) => (
          <Nav.Item key={index}>
            {item.submenu ? (
              <>
                <div
                  className="nav-link"
                  onClick={() => handleSubMenuToggle(item.label)}
                >
                  {item.icon} {!isCollapsed && item.label}
                </div>
                {openMenu === item.label && !isCollapsed && (
                  <div className="sub-menu">
                    {item.submenu.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subItem.link}
                        className="sub-nav-link"
                      >
                        {subItem.label}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <Link to={item.link} className="nav-link">
                {item.icon} {!isCollapsed && item.label}
              </Link>
            )}
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};

export default Sidebar;
