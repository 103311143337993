import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import "./Top.css";

const Top = ({ isSidebarCollapsed }) => {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/"); // Redirect to homepage
  };

  return (
    <div
      className="top"
      style={{
        marginLeft: isSidebarCollapsed ? "75px" : "225px",
        transition: "margin-left 0.3s ease",
      }}
    >
      <div
        className={`profile-section ${
          isSidebarCollapsed ? "expanded" : ""
        } d-flex align-items-center`}
      >
        <img
          src="https://via.placeholder.com/40"
          alt="Profile"
          className="profile-img me-2"
        />
        <span className="username">Hello Doctor</span>
      </div>
      <div className="logout-section">
        {showLogoutConfirm ? (
          <div className="logout-confirm">
            <span>Are you sure?</span>
            <div className="buttons-container">
              <button onClick={handleLogout} className="confirm-btn">
                Yes
              </button>
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <FiLogOut
            size={24}
            color="white"
            onClick={() => setShowLogoutConfirm(true)}
            className="logout-icon"
          />
        )}
      </div>
    </div>
  );
};

export default Top;
