import React, { useState } from "react";
import Sidebar from "./Sidebar"; // Import Sidebar
import Top from "./Top";
import PatientList from "./PatientList";
import AppointmentStatus from "./AppointmentStatus";
import { Container, Row, Col } from "react-bootstrap";
import "./Dashboard.css";

const Dashboard = () => {
  // Set the initial state of the sidebar to collapsed
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed((prev) => !prev); // Toggle between collapsed and expanded
  };

  return (
    <div className={`dashboard ${isSidebarCollapsed ? "sidebar-collapsed" : ""}`}>
      <Sidebar isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
      <div className="dashboard-content">
        {/* Pass `isSidebarCollapsed` state to the `Top` component */}
        <Top isSidebarCollapsed={isSidebarCollapsed} />
        <Container fluid>
          <Row>
            <Col md={4}>
              <AppointmentStatus />
            </Col>
            <Col md={8}>
              <PatientList />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Dashboard;
