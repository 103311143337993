import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase"; // Ensure this is correctly initialized
import "./PatientList.css";

// Initialize Firestore
const db = getFirestore();

const PatientList = () => {
  const [patients, setPatients] = useState([]); // Store patient data in state

  useEffect(() => {
    // Fetch patient data from Firestore when the component mounts
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const allPatientData = [];

      // Fetch data from "Booking" collection
      const bookingSnapshot = await getDocs(collection(db, "Booking"));
      bookingSnapshot.forEach((doc) => {
        allPatientData.push({
          name: doc.data().name,
          age: doc.data().age,
          condition: doc.data().condition,
          source: 'Booking',
        });
      });

      // Fetch data from "Request" collection
      const requestSnapshot = await getDocs(collection(db, "Request"));
      requestSnapshot.forEach((doc) => {
        allPatientData.push({
          name: doc.data().name,
          age: doc.data().age,
          condition: doc.data().condition,
          source: 'Request',
        });
      });

      setPatients(allPatientData); // Set all fetched data to state
    } catch (error) {
      console.error("Error fetching patients: ", error); // Handle errors
    }
  };

  return (
    <div className="patient-list">
      <h3>Patient List</h3>
      {patients.length > 0 ? (
        <ul>
          {patients.map((patient, index) => (
            <li key={index}>
              <h4>{patient.name}</h4>
              <p>Age: {patient.age}</p>
              <p>Condition: {patient.condition}</p>
              <p>Source: {patient.source}</p> {/* Display the source collection */}
            </li>
          ))}
        </ul>
      ) : (
        <p>No patients found.</p>
      )}
    </div>
  );
};

export default PatientList;
