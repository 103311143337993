import React, { useEffect, useState } from "react";
import "./AppointmentStatus.css";
import { auth, firestore } from "../firebase";
import { collection, query, where, getDocs, onSnapshot, addDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Account from "../Doctor/Account";

const AppointmentStatus = () => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [doctorFound, setDoctorFound] = useState(true);
  const [showAccount, setShowAccount] = useState(false);
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false); // Track if button is disabled
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppointments = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const email = user.email;
      try {
        const doctorQuery = query(
          collection(firestore, "Doctors"),
          where("Corporate ID", "==", email)
        );
        const doctorSnapshot = await getDocs(doctorQuery);

        if (doctorSnapshot.empty) {
          console.error("Doctor document not found");
          setError("Doctor document not found.");
          setDoctorFound(false);
          return;
        }

        const doctorId = doctorSnapshot.docs[0].get("DoctorID");

        const appointmentQuery = query(
          collection(firestore, "Booking"),
          where("doctorId", "==", doctorId),
          where("date", "==", formatDate(selectedDate))
        );

        const unsubscribe = onSnapshot(appointmentQuery, (snapshot) => {
          const fetchedAppointments = snapshot.docs.map((doc) => ({
            date: doc.get("date"),
            patient: doc.get("name"),
            status: doc.get("status"),
          }));
          setAppointments(fetchedAppointments);
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching appointments:", error.message);
        setError("Error fetching appointments.");
      }
    };

    fetchAppointments();
  }, [selectedDate, appointmentCreated]);

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${
      (date.getMonth() + 1).toString().padStart(2, '0')
    }/${date.getFullYear()}`;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };

  const handleCreateAccount = () => {
    setShowAccount(true);
    setButtonDisabled(true); // Disable the button when clicked
  };

  const handleAppointmentCreation = async (appointmentData) => {
    try {
      const user = auth.currentUser;
      const email = user.email;

      const doctorQuery = query(
        collection(firestore, "Doctors"),
        where("Corporate ID", "==", email)
      );
      const doctorSnapshot = await getDocs(doctorQuery);
      const doctorId = doctorSnapshot.docs[0].get("DoctorID");

      await addDoc(collection(firestore, "Booking"), {
        doctorId: doctorId,
        patient: appointmentData.patient,
        date: formatDate(selectedDate),
        status: appointmentData.status,
      });

      setShowAccount(false);
      setAppointmentCreated(true);

    } catch (error) {
      console.error("Error creating appointment:", error.message);
      setError("Error creating appointment.");
    }
  };

  const handleSkip = () => {
    setShowAccount(false); // Close the Account form
    setButtonDisabled(false); // Enable the "Create Account" button again
  };
  

  return (
    <div className="appointment-status">
      <h3>Appointment Status</h3>
      {error && !doctorFound && (
        <div>
          <p className="error">{error}</p>
          <button
            onClick={handleCreateAccount}
            className={`create-account-button ${buttonDisabled ? "disabled" : ""}`}
            disabled={buttonDisabled}
          >
            Create Account
          </button>
        </div>
      )}

      {doctorFound && (
        <>
          <div className="header-row">
            <div
              className="current-date"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <p>Current Date: {formatDate(selectedDate)}</p>
            </div>
            <div className="appointments-count">
              <p>Appointments: {appointments.length}</p>
            </div>
          </div>

          {showDatePicker && (
            <div className="date-picker-popup">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                dateFormat="dd/MM/yyyy"
              />
            </div>
          )}

          <ul>
            {appointments.length > 0 &&
              appointments.map((appointment, index) => (
                <li key={index}>
                  <p>Date: {appointment.date}</p>
                  <p>Patient: {appointment.patient}</p>
                  <p>Status: {appointment.status}</p>
                </li>
              ))}
          </ul>
        </>
      )}

{showAccount && (
  <div>
    <Account onAppointmentCreate={handleAppointmentCreation} />
    <p className="skip-text" onClick={handleSkip}>
      Skip
    </p>
  </div>
      )}
    </div>
  );
};

export default AppointmentStatus;


