import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { Button, Input, Form, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const [doctorHospital, setDoctorHospital] = useState('');
  const [doctorAddress, setDoctorAddress] = useState('');
  const [doctorLocation, setDoctorLocation] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const extractCoordinates = (url) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
      return {
        latitude: parseFloat(match[1]),
        longitude: parseFloat(match[2]),
      };
    }
    return null;
  };

  const handleSubmit = async () => {
    if (doctorHospital && doctorAddress && doctorLocation && selectedImage) {
      setLoading(true);

      // Extract coordinates from the location URL
      const coordinates = extractCoordinates(doctorLocation);
      if (!coordinates) {
        setLoading(false);
        message.error('Invalid location URL');
        return;
      }

      // Upload image to Firebase Storage
      const storageRef = ref(storage, `Hospitals/${Date.now()}_${selectedImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedImage);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          setLoading(false);
          message.error('Image upload failed!');
        },
        async () => {
          // Get image URL after upload is complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Save doctor data to Firestore
          const doctorData = {
            doctorHospital,
            doctorAddress,
            doctorLocation,
            image: downloadURL,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          };

          try {
            await setDoc(doc(db, 'Hospitals', Date.now().toString()), doctorData);
            setLoading(false);
            message.success('Doctor details saved successfully!');
            navigate('/dashboard'); // Navigate to dashboard after saving
          } catch (error) {
            setLoading(false);
            message.error('Failed to save doctor details');
          }
        }
      );
    } else {
      message.warning('Please fill in all fields and select an image.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Add Doctor Information</h2>
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Hospital/Clinic Name">
            <Input
              value={doctorHospital}
              onChange={(e) => setDoctorHospital(e.target.value)}
              placeholder="Enter Hospital/Clinic Name"
            />
          </Form.Item>

          <Form.Item label="Address">
            <Input
              value={doctorAddress}
              onChange={(e) => setDoctorAddress(e.target.value)}
              placeholder="Enter Address"
            />
          </Form.Item>

          <Form.Item label="City or Location URL">
            <Input
              value={doctorLocation}
              onChange={(e) => setDoctorLocation(e.target.value)}
              placeholder="Enter City Name or Google Maps URL"
            />
          </Form.Item>

          <Form.Item label="Upload Logo">
            <input type="file" onChange={handleImageChange} />
          </Form.Item>

          <Button
            type="primary"
            onClick={handleSubmit}
            icon={<UploadOutlined />}
            disabled={loading}
          >
            Save Details
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

export default Account;
